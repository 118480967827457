


















































































































































































































































































































































































.el-rate__icon {
  margin-right: 0;
}
